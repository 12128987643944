<template>
  <div class="modal-change-account">
    <div class="modal-wrapper-change-account">
      <div class="overlay-modal-change-account" v-show="flagOverlayModalPin" />
      <div
        class="modal-wrapper-container-change-account"
        :style="`opacity: ${flagOverlayModalPin ? 0.7 : 1};`"
        @click="removeBlur"
      >
        <div class="modal-container-change-account">
          <div class="modal-content-change-account">
            <div class="container-modal-title-change-account" v-if="!isUnlink">
              <div class="account-photo">
                <img
                  :src="userLocal.photo"
                  alt="foto do usuário"
                  v-if="userLocal.photo"
                />
                <span v-else>
                  {{
                    userLocal.first_name[0] +
                    userLocal.last_name.split(" ").at(-1)[0]
                  }}
                </span>
              </div>
              <div class="container-account-name">
                <span class="account-name ellipsis-change-account">
                  {{ `${userLocal.first_name} ${userLocal.last_name}` }}
                </span>
              </div>
            </div>
            <div class="error-card" v-show="invalidPin">
              <p class="error-message">
                {{ $t("ChangeAccountPage.ModalErrorMessage") }}
              </p>
            </div>
            <div>
              <p
                class="modal-subtitle-change-account"
                v-if="!isUnlink && !invalidPin"
              >
                {{ $t("ChangeAccountPage.ModalSubtitle") }}
              </p>
              <p
                class="modal-subtitle-change-account"
                v-if="isUnlink && !invalidPin"
              >
                {{ $t("ChangeAccountPage.ToUnlink") }}
                <strong>{{
                  `${userLocal.first_name} ${userLocal.last_name}`
                }}</strong>
                {{ $t("ChangeAccountPage.EnterYourPin") }}
              </p>
            </div>
            <div class="pin-container">
              <div class="pin-container-wrapper" @click="setFocusInput()">
                <div
                  v-for="index in 4"
                  class="block-pin-container"
                  :key="`block-pin-${index}`"
                >
                  <div v-show="pin.length > index - 1">
                    <svg
                      width="14"
                      height="14"
                      viewBox="0 0 14 14"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <circle cx="7" cy="7" r="6.5" fill="#4C4541" />
                    </svg>
                  </div>
                </div>
              </div>
            </div>
            <button
              class="modal-button-cancel-change-account"
              @click="
                isInativeModal
                  ? cancelOperationInativeModal()
                  : showModal('close')
              "
            >
              {{ $t("ChangeAccountPage.ChangeAccount") }}
            </button>
            <form autocomplete="off">
              <input
                id="input-pin"
                ref="inputPin"
                v-model="pin"
                maxlength="4"
                inputmode="numeric"
                @blur="setFocusInput"
                @keydown="filterInput($event)"
                @input="maxInput($event)"
              />
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import IconLogout from "@/assets/images/pages/logout-icon.svg";
import IconConnect from "@/assets/images/pages/connect-icon.svg";
import IconClose from "@/assets/images/pages/close-button-icon.svg";
import IconLoadSpinner from "@/assets/images/pages/load-icon.svg";
import { mapActions, mapGetters, mapMutations } from "vuex";
import { initialAbility } from "@/libs/acl/config";

export default {
  components: {
    IconLogout,
    IconConnect,
    IconClose,
    IconLoadSpinner,
  },
  data() {
    return {
      prefix:
        localStorage.getItem("prefix") ||
        this.$router.currentRoute.params.prefix,
      pin: "",
      invalidPin: false,
      isFirstName: true,
      userLocal: {
        first_name: "",
        last_name: "",
        photo: "",
      },
      currentSite: {},
    };
  },
  props: {
    user: {
      type: Object,
    },
    showModal: {
      type: Function,
    },
    isUnlink: {
      type: Boolean,
    },
    getAccounts: {
      type: Function,
    },
    currentGuid: {
      type: String,
    },
    isInativeModal: {
      type: Boolean,
    },
  },
  computed: {
    ...mapGetters("app", ["flagOverlayModalPin"]),
  },
  mounted() {
    this.userLocal = this.user ?? null;
    if (this.isInativeModal) {
      this.userLocal = JSON.parse(localStorage.getItem("userData"));
      this.currentSite = JSON.parse(localStorage.getItem("currentSite"));
    }
    !this.isInativeModal && this.setFocusInput();
  },
  methods: {
    ...mapMutations("app", [
      "UPDATE_FLAG_SHOW_OVERLAY",
      "UPDATE_FLAG_SHOW_MODAL_PIN",
      "UPDATE_FLAG_OVERLAY_MODAL_PIN",
    ]),
    ...mapActions("app", ["removeRequestLoading"]),
    confirmPin() {
      this.UPDATE_FLAG_SHOW_OVERLAY(true);

      const payload = {
        pin: this.pin,
        user_guid: this.userLocal.guid,
        site_guid: this.currentSite.guid,
      };

      this.$http
        .post(
          `/api/${this.$router.currentRoute.params.prefix}/switch-user`,
          payload
        )
        .then((response) => {
          const { data } = response;

          if (this.isUnlink) {
            this.logoutAccount();
            this.removeRequestLoading();
            return;
          }

          const loggedAccountsInfo = JSON.parse(
            localStorage.getItem("loggedAccountsInfo")
          );
          const accountIndex = loggedAccountsInfo.findIndex(
            (user) => user.guid == data.userData.guid
          );
          const removedUser = loggedAccountsInfo.splice(accountIndex, 1)[0];
          loggedAccountsInfo.unshift(removedUser);

          localStorage.setItem(
            "loggedAccountsInfo",
            JSON.stringify(loggedAccountsInfo)
          );

          localStorage.setItem("userData", JSON.stringify(data.userData));
          localStorage.setItem("accessToken", data.accessToken);
          localStorage.setItem("time_to_lock", data.time_to_lock);



          localStorage.setItem("blockScreen", false);

          if (this.isInativeModal) {
            this.UPDATE_FLAG_SHOW_MODAL_PIN(false);
            this.removeRequestLoading();
            this.$emit("resetInactivityTime");
            const { currentSite } = this.$cookies.get("userInfo");
            const domain = window.location.href.includes("indsiders.com");
            this.$cookies.set(
                "userInfo",
                {...data, currentSite: currentSite },
                null,
                null,
                domain ? "indsiders.com" : null
            );
            return;
          }

          this.getSites(data);
        })
        .catch((e) => {
          console.error(e);
          this.invalidPin = true;
          this.pin = "";
          this.setFocusInput();
          this.removeRequestLoading();
        });
    },
    getSites(userInfo) {
      this.$http
        .get(`/api/${this.$router.currentRoute.params.prefix}/tenant-site`)
        .then((response) => {
          const sites = response.data;
          const currentSite = JSON.parse(localStorage.getItem("currentSite"));
          const found = sites.find((site) => site.guid == currentSite.guid);
          const domain = window.location.href.includes("indsiders.com");
          localStorage.setItem("sites", JSON.stringify(sites));

          if (!found) {
            localStorage.setItem("currentSite", JSON.stringify(sites[0]));
          }

           this.$cookies.set(
               "userInfo",
               {...userInfo, currentSite: !!found ? currentSite : sites[0] },
               null,
               null,
               domain ? "indsiders.com" : null
           );

          this.showModal("close");

          setTimeout(() => {
            this.redirect();
            this.removeRequestLoading();
          }, 1000);
        })
        .catch((e) => {
          console.error(e);
          this.removeRequestLoading();
        });
    },
    redirect() {
      this.$router.push({ path: `/${this.prefix}/operator` });
    },
    filterInput(event) {
      const input = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
      const keyPressed = event.key;
      const keyCode = event.keyCode ? event.keyCode : event.which;

      if (!input.includes(keyPressed) && keyCode != 8) event.preventDefault();
    },
    maxInput(value) {
      if (value.length > 4) {
        this.setFocusInput();

        return;
      }

      if (value.length <= 4 && value != "") {
        this.pin = value;
      } else if (value == "") {
        this.pin = "";
      } else {
        this.pin = this.pin;
      }

      if (this.pin.length == 3 && this.invalidPin) this.invalidPin = false;

      if (this.pin.length == 4) this.confirmPin();

      this.setFocusInput();
    },
    setFocusInput() {
      Object.keys(this.$refs).includes("inputPin") &&
        this.$refs.inputPin?.focus();
    },
    logoutAccount() {
      let list = JSON.parse(localStorage.getItem("loggedAccountsInfo"));
      const currentGuidIndex = list
        .map((item) => item.guid)
        .indexOf(this.userLocal.guid);
      list.splice(currentGuidIndex, 1);

      localStorage.setItem("loggedAccountsInfo", JSON.stringify(list));

      if (this.currentGuid == this.userLocal.guid) {
        const payload = {
          tokenId: localStorage.getItem("tokenId"),
          guid: this.userLocal.guid,
        };

        this.$http
          .post(
            `/api/${this.$router.currentRoute.params.prefix}/users/logout `,
            payload
          )
          .then(() => {
            localStorage.clear();

            // Reset ability
            this.$ability.update(initialAbility);

            // Delete cookie
            const domain = window.location.href.includes("indsiders.com");
            this.$cookies.remove(
              "userInfo",
              null,
              domain ? "indsiders.com" : null
            );
            // Redirect to login page
            this.showModal("close");

            setTimeout(
              () => (window.location.href = `${this.$myIndLink}login`),
              1000
            );
          });

        return;
      }

      this.showModal("close");
      this.getAccounts();
    },
    cancelOperation() {
      this.showModal("close");
      this.$router.push({ name: "change-account" });
    },
    cancelOperationInativeModal() {
      !window.location.pathname.includes("change-account") &&
        this.$router.push({ name: "change-account" });

      this.UPDATE_FLAG_SHOW_MODAL_PIN(false);
    },
    removeBlur() {
      $(".header-navbar").removeClass("overlay-navbar");

      this.UPDATE_FLAG_OVERLAY_MODAL_PIN(false);
      this.setFocusInput();
    },
  },
};
</script>

<style lang="scss">
.overlay-modal-change-account {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100dvh;
  background: rgba(0, 0, 0, 0.25);
  backdrop-filter: blur(5px);
  z-index: 99999991;
}

.modal-change-account {
  min-height: 100%;
  width: 100%;
  z-index: 99999990;

  position: fixed;
  top: 0;
  left: 0;

  background-color: #fcfcfc;

  #input-pin {
    height: 0;
    width: 0;
    opacity: 0;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    position: absolute;
  }

  .ellipsis-change-account {
    white-space: nowrap;
    max-width: 95%;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .modal-wrapper-change-account {
    width: 100vw;
    height: 100dvh;
    position: relative;
    background-color: transparent;

    .modal-wrapper-container-change-account {
      position: absolute;
      left: 50%;
      top: calc(37.3% + 60px);
      z-index: 99999992;
      transform: translate(-50%, -50%);

      .modal-container-change-account {
        max-width: 525px;
        width: 90vw;
        background-color: #fafafa;
        box-shadow: 0px 4px 24px rgba(58, 46, 38, 0.1);
        border: 1px solid #cfc4be;
        border-radius: 24px;
        position: relative;

        .modal-content-change-account {
          z-index: 99999993;
          padding: 32px;

          .container-modal-title-change-account {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
            margin-bottom: 16px;

            .account-photo {
              overflow: hidden;
              width: 80px;
              height: 80px;
              border-radius: 50%;
              background: #ffc697;
              position: relative;
              user-select: none;

              img {
                width: 100%;
                height: 100%;
                object-fit: cover;
              }

              span {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                color: #512400;
                font-weight: 600;
                font-size: 36px;
                line-height: 50px;
                text-transform: uppercase;
                line-height: 1;
              }
            }

            .container-account-name {
              display: flex;
              flex-direction: column;
              align-items: center;
              margin-top: 8px;
              width: 100%;
              overflow: hidden;
              padding: 0 20px;

              .account-name {
                font-weight: 600;
                font-size: 22px;
                line-height: 30px;
                color: #4c4541;
                max-width: 100%;
              }
            }
          }

          .error-card {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 16px;

            background: #ffebee;
            border: 1px solid #b71c1c;
            border-radius: 6px;
            margin-bottom: 16px;

            .error-message {
              font-weight: 400;
              font-size: 14px;
              line-height: 20px;
              color: #b71c1c;
              margin: 0;
            }
          }

          .modal-subtitle-change-account {
            text-align: center;
            margin: 0;
            padding: 0 10px;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #998f8a;

            strong {
              color: #4c4541;
            }
          }

          .pin-container {
            display: flex;
            margin: 16px 0;
            justify-content: center;

            .pin-container-wrapper {
              gap: 10px;
              display: flex;
              cursor: pointer;
              .block-pin-container {
                display: flex;
                flex-direction: row;
                justify-content: center;
                align-items: center;
                padding: 8px;

                width: 73px;
                height: 66px;

                background: #efe6dd;
                border-radius: 6px;
              }
            }
          }

          .modal-button-cancel-change-account {
            background-color: #fafafa;
            margin: 0 auto;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            padding: 13px 28px;
            gap: 10px;
            border: none;

            height: 46px;

            font-size: 17px;
            font-weight: 600;
            line-height: 20px;
            letter-spacing: 0.213px;
            border-radius: 5px;
            color: #974900;

            &:hover {
              background-color: #ffede2;
            }

            &:active {
              background-color: #ffdbc4;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 768px) {
  .modal-wrapper-container-change-account {
    top: calc(37.3% + 32px);
  }
}
@media (max-width: 480px) {
  .modal-change-account {
    .modal-wrapper-change-account {
      .modal-wrapper-container-change-account {
        top: calc(37.3% + 32px);

        .modal-container-change-account {
          .modal-content-change-account {
            padding: 16px !important;

            .container-modal-title-change-account {
              .account-photo {
                width: 60px !important;
                height: 60px !important;

                span {
                  font-size: 22px !important;
                  line-height: 30px !important;
                }
              }

              .container-account-name {
                .account-name {
                  font-size: 16px !important;
                  line-height: 26px !important;
                }
              }
            }

            .modal-subtitle-change-account {
              text-align: center !important;
              margin: 0 !important;
              padding: 0 10px !important;
              font-weight: 400 !important;
              font-size: 16px !important;
              line-height: 22px !important;

              strong {
                color: #4c4541 !important;
              }
            }

            .pin-container {
              display: flex !important;
              margin: 16px 0 !important;
              justify-content: center !important;

              .pin-container-wrapper {
                gap: 10px !important;
                display: flex !important;
                cursor: pointer !important;
                .block-pin-container {
                  width: 58px !important;
                  height: 52px !important;
                }
              }
            }

            .modal-button-cancel-change-account {
              font-size: 14px !important;
              line-height: 20px !important;
            }
          }
        }
      }
    }
  }
}
</style>
